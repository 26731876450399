export const roundMoney = (val: number) => Math.round(val * 100) / 100;

export const getNumber = (n: any, def: number) => (isNaN(n) ? def : +n);

// NP-hard problem: https://en.wikipedia.org/wiki/Subset_sum_problem
export const findClosestSum = (numbers: number[], target: number) => {
  const nonZeros = numbers.filter(n => n !== 0).map(Math.round);
  return findClosestSumDP(nonZeros, target);
};

// Dynamic programming: O(n*target)
export const findClosestSumDP = (numbers: number[], target: number) => {
  const n = numbers.length;
  const dp = Array.from({ length: n + 1 }, () => Array(target + 1));
  let i = 0;
  let j = 0;

  // result is always true for sum = 0
  for (i = 0; i <= n; i++) {
    dp[i][0] = true;
  }

  // dp[i][j] represents whether it is possible to achieve a sum of j using the first i numbers from the array
  for (i = 1; i <= n; i++) {
    for (j = 1; j <= target; j++) {
      dp[i][j] = dp[i - 1][j];
      if (j >= numbers[i - 1]) {
        dp[i][j] = dp[i][j] || dp[i - 1][j - numbers[i - 1]];
      }
    }
  }

  // find the closest sum by iterating from the target sum downwards and finding the first sum that is achievable
  let closestSum = 0;
  for (j = target; j >= 0; j--) {
    if (dp[n][j]) {
      closestSum = j;
      break;
    }
  }

  // reconstruct the best combination by backtracking through the dp array and adding the chosen numbers to the bestCombination array
  const bestCombination: number[] = [];
  i = n;
  j = closestSum;
  while (i > 0 && j > 0) {
    if (dp[i - 1][j]) {
      i--;
      continue;
    }
    bestCombination.push(numbers[i - 1]);
    j -= numbers[i - 1];
    i--;
  }

  return bestCombination;
};
