import { SelectItem } from 'primeng/api';

export const removeObjectValues = (obj: any, condition: (value: any, key: string) => boolean): any =>
  Object.entries(obj).reduce((acc, [k, v]) => {
    if (condition(v, k)) {
      return acc;
    }
    if (v && typeof v === 'object' && Object.getPrototypeOf(v) === Object.prototype) {
      return Object.assign(acc, { [k]: removeObjectValues(v, condition) });
    }
    return Object.assign(acc, { [k]: v });
  }, {});

export const replaceObjectValues = (obj: any, replaceFunc: (value: any, key: string) => any): any =>
  Object.entries(obj).reduce((acc, [k, v]) => {
    if (v && typeof v === 'object' && Object.getPrototypeOf(v) === Object.prototype) {
      return Object.assign(acc, { [k]: replaceObjectValues(v, replaceFunc) });
    }
    return Object.assign(acc, { [k]: replaceFunc(v, k) });
  }, {});

export const sumObjectsField = <T>(objects: T[], field: keyof T) => objects.reduce((acc, v) => acc + +v[field], 0);

export const enumOfStringsToOptions = <T = string>(enumValue: object): SelectItem<T>[] =>
  Object.entries(enumValue).map(([index, value]) => ({ label: index, value }));

export const enumOfStringsToOptionsReversed = (enumValue: object): SelectItem<string>[] =>
  Object.entries(enumValue).map(([index, value]) => ({ label: value.toString(), value: index }));

export const enumToOptions = <T = number>(enumValue: object): SelectItem<T>[] =>
  enumOfStringsToOptions<T>(enumValue).filter((option): option is SelectItem<T> => typeof option.value === 'number');

export const optionsToMap = <T extends string | number | symbol>(options: SelectItem<T>[]): Record<T, string> =>
  options.reduce((acc, opt) => Object.assign(acc, { [opt.value]: opt.label }), {} as Record<T, string>);

export const deepFlatWhenTotalLessThan = <T>(
  items: T[],
  childrenField: keyof T,
  limit: number,
  keepField?: keyof T,
  result = [] as T[],
  original = items,
): T[] => {
  if (result === original || Math.max(original.length, result.length) >= limit) {
    return items;
  }
  for (const item of items) {
    if (!item[childrenField] || (keepField && item[keepField])) {
      result.push(item);
    } else {
      deepFlatWhenTotalLessThan(item[childrenField] as any, childrenField, limit, keepField, result, original);
    }
    if (result.length >= limit) {
      return original;
    }
  }
  return result;
};

export interface ObjectDiff {
  [key: string]: { a: any; b: any } | ObjectDiff;
}
export const objectDiff = (objA: any, objB: any, skipEmpty = false) =>
  Object.entries(objB).reduce((acc, [key, value]) => {
    const currentValue = objA[key];
    if (value === currentValue) {
      return acc;
    }
    if (skipEmpty && (!value || !currentValue)) {
      return acc;
    }
    if (typeof value === 'object' && value !== null && Object.getPrototypeOf(value) === Object.prototype) {
      const nestedDiff = objectDiff(currentValue, value, skipEmpty);
      if (Object.keys(nestedDiff).length) {
        acc[key] = nestedDiff;
      }
    } else {
      acc[key] = { a: currentValue, b: value };
    }
    return acc;
  }, {} as ObjectDiff);

export const deepCopyIf = <T extends object>(
  from: T,
  to: T,
  condition: (valueFrom: any, valueTo: any, field?: string | number | symbol) => boolean,
) => {
  for (const key of Object.keys(from) as (keyof T)[]) {
    const valueFrom = from[key];
    if (to[key] === undefined) {
      if (isObject(valueFrom)) {
        to[key] = {} as any;
      }
      if (Array.isArray(valueFrom)) {
        to[key] = [] as any;
      }
    }
    const valueTo = to[key];
    if ((isObject(valueFrom) && !!valueTo) || Array.isArray(valueFrom)) {
      deepCopyIf(valueFrom, valueTo as any, condition);
    } else if (condition(valueFrom, valueTo, key)) {
      to[key] = valueFrom;
    }
  }
  return to;
};

export const isObject = (valueFrom: any): boolean =>
  typeof valueFrom === 'object' && valueFrom !== null && Object.getPrototypeOf(valueFrom) === Object.prototype;
